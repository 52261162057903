import React, { useEffect, useState } from 'react';
import paymentFailedIcon from '../../assets/images/failed.svg';
import PaymentButton from './paymentButton.component';
import * as constants from '../../app.constant';
import { Trans } from "gatsby-plugin-react-i18next"
export default function PaymentFailed() {
    const [isMobile, setMobile] = useState(false)
    useEffect(() => {
       setMobile(constants.isMobile())
    }, [])
    return (
        <div className="payment-page-wrapper">
            <div className="payment-box">
                <div className="text-center" style={{borderBottom: '1px solid #DBDBDB', paddingBottom:'10px', marginBottom: '25px'}}>
                    <p className="ps-icon"><img src={paymentFailedIcon} alt="" width="44" /></p>
                    <p style={{fontFamily: '"SamsungOneUILatin 700", san-serif', fontSize: '24px', lineHeight: '24px'}}><Trans>failed.content.label1</Trans></p>
                    <p style={{fontFamily: '"SamsungOneUILatin 400", sans-serif', fontSize: '17px', padding: '0px 20px'}}><Trans>failed.content.label2</Trans></p>
                </div>
                <p className='textCenter' style={{marginBottom: '25px'}}>
                    <Trans>failed.content.label5</Trans> {!isMobile && <br/>}
                    {constants.env.GATSBY_COUNTRY === 'BE' && (<a href="mailto:contact.be@careplus.co">contact.be@careplus.co </a>)}
                    {constants.env.GATSBY_COUNTRY === 'NL' && (<a href="mailto:contact.nl@careplus.co">contact.nl@careplus.co </a>)}
                    {constants.env.GATSBY_COUNTRY === 'FI' && (<><a href="mailto:contact.fi@careplus.co">contact.fi@careplus.co</a>.</>)}
                </p>
                <PaymentButton paymentSucessPage={false} />
            </div>
        </div>

    )
}