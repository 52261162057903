import React, { useEffect } from 'react';
import Layout from '../components/shared/layout';
import PaymentFailed from '../components/paymentResponse/paymentFailed.component';
import { graphql } from "gatsby"
import { ROUTES } from '../app.constant';
import loaderStore from '../store/loaderStore';

export default function PaymentFailedPage() {
    useEffect(() => {
        document.title = '';
      // force hide loader
      loaderStore.showLoader(false)
    }, [])

    return (
        <Layout header={true} isBackBtn={true} prevUrl={ROUTES.PERSONAL_INFO} isPayment={true}>
            <PaymentFailed></PaymentFailed>
        </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`